import React from 'react';
import { useSelector } from 'react-redux';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { groupsTabClicked } from '@wix/bi-logger-groups/v2';

import { GroupAppKey } from 'store/groups/types';
import {
  selectGroupSlug,
  selectIsAppAvailable,
  selectIsJoinedGroupMember,
  selectMemberLabel,
  selectMembers,
  selectMembersCount,
  selectMembersMetadata,
  selectSeeAllMemberLabel,
} from 'store/selectors';

import { SEE_ALL_MEMBER_LABELS } from 'store/groups/constants';

import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';

import {
  FollowButton,
  ProfileListItem,
} from 'common/components/ProfileListItem';
import { Link } from 'common/components/Link';

import { List } from 'wui/List';
import { Card } from 'wui/Card';
import { CardHeader } from 'wui/CardHeader';
import { CardContent } from 'wui/CardContent';
import { CardActions } from 'wui/CardActions';
import { EmptyState } from 'wui/EmptyState';
import { ListItemAvatar } from 'wui/ListItemAvatar';
import { ListItem } from 'wui/ListItem';
import { Skeleton } from 'wui/Skeleton';
import { TextButton } from 'wui/TextButton';
import { ListItemText } from 'wui/ListItemText';
import { ErrorState } from 'wui/ErrorState';

interface IProps {
  groupId: string;
}

export function GroupMembers(props: IProps) {
  const { groupId } = props;
  const bi = useBi();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const { members$ } = useController();
  const meta = useSelector(selectMembersMetadata);
  const members = useSelector(selectMembers).slice(0, 5);
  const slug = useSelector(selectGroupSlug(groupId));
  const isJoined = useSelector(selectIsJoinedGroupMember(groupId));
  const membersLabel = useSelector(selectMemberLabel(groupId));
  const seeAllMembersLabel = useSelector(selectSeeAllMemberLabel(groupId));
  const membersCount = useSelector(selectMembersCount(groupId));
  const isAvailable = useSelector(
    selectIsAppAvailable({
      groupId,
      application: GroupAppKey.MEMBERS_APP,
    }),
  );

  useDidUpdate(() => {
    if (isAvailable) {
      members$.fetch(groupId, 5);
    }
  }, [isAvailable, isJoined]);

  if (!isAvailable) {
    return null;
  }

  return (
    <Card sideBorders={!isMobile} data-hook="members-widget">
      <CardHeader wired title={t(membersLabel.label, { count: 0 })} />
      <CardContent>
        {(() => {
          if (meta.loading) {
            return (
              <List disablePadding gap="SP3">
                {new Array(3).fill(0).map((_, index) => (
                  <ListItem disablePadding key={index}>
                    <ListItemAvatar>
                      <Skeleton variant="circular" width={36} height={36} />
                    </ListItemAvatar>
                    <ListItemText title={<Skeleton />} />
                  </ListItem>
                ))}
              </List>
            );
          }

          if (meta.error) {
            return (
              <ErrorState
                wired
                variant="section"
                subtitle={t('groups-web.error-state.title')}
                actionLabel={t('groups-web.error-state.retry.label')}
              />
            );
          }

          if (!members.length) {
            return (
              <EmptyState
                wired
                variant="section"
                subtitle={t('groups-web.discussion.members.empty')}
              />
            );
          }

          return (
            <List disablePadding gap="SP3">
              {members.map((member) => (
                <ProfileListItem
                  wired
                  disablePadding
                  profile={member}
                  key={member.memberId}
                >
                  <FollowButton
                    wired
                    as={TextButton}
                    variant="primary"
                    profile={member}
                  />
                </ProfileListItem>
              ))}
            </List>
          );
        })()}
      </CardContent>
      <CardActions>
        <Link
          wired
          variant="primary"
          state="group.members"
          params={{ slug }}
          onClick={handleOpenMembersClick}
        >
          {t(
            seeAllMembersLabel.isCustom
              ? SEE_ALL_MEMBER_LABELS.Custom
              : seeAllMembersLabel.label,
            {
              membersLabel: t(membersLabel.label),
              total: membersCount,
            },
          )}{' '}
        </Link>
      </CardActions>
    </Card>
  );

  function handleOpenMembersClick() {
    bi.report(
      groupsTabClicked({
        group_id: groupId,
        origin: 'see_all_members_link',
        name: 'members',
      }),
    );
  }
}

GroupMembers.displayName = 'GroupMembers';
