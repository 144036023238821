import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSelector } from 'react-redux';

import { useCurrentStateAndParams, useRouter } from '@wix/tpa-router/react';
import { Type } from '@wix/ambassador-social-groups-v2-group/types';

import { groupsRequestJoinAGroup } from '@wix/bi-logger-groups/v2';

import {
  selectGroupIdBySlug,
  selectGroupPrivacyStatus,
  selectGroupRestriction,
  selectIsAppAvailable,
} from 'store/selectors';
import { GroupAppKey } from 'store/groups/types';

import { EmptyState } from 'wui/EmptyState';
import { ErrorState } from 'wui/ErrorState';

import { GroupMembershipButton } from 'common/components/GroupMembership';

interface IProtectedPageProps {
  application: GroupAppKey;
  children?: React.ReactNode;
}

export function RestrictedState(props: IProtectedPageProps) {
  const { application, children } = props;

  const { t } = useTranslation();
  const router = useRouter();
  const { params } = useCurrentStateAndParams();

  const groupId = useSelector(selectGroupIdBySlug(params.slug));
  const restriction = useSelector(selectGroupRestriction(groupId));
  const privacyStatus = useSelector(selectGroupPrivacyStatus(groupId));
  const isAppAvailable = useSelector(
    selectIsAppAvailable({
      groupId,
      application,
    }),
  );

  if (isAppAvailable) {
    return <>{children}</>;
  }

  switch (restriction) {
    case Type.EVENTS:
    case Type.PAID_PLANS:
      return (
        <EmptyState
          wired
          data-hook="restricted-join"
          title={t('groups-web.restriction.join')}
          subtitle={t('groups-web.restriction.join.subtitle')}
          action={
            <GroupMembershipButton
              groupId={groupId}
              bi={groupsRequestJoinAGroup({
                group_id: groupId,
                origin: 'group_feed_inside_page_btn',
                type: (privacyStatus || '').toLowerCase(),
              })}
            />
          }
        />
      );

    case Type.UNKNOWN:
      return (
        <ErrorState
          wired
          onRetry={handleRetry}
          title={t('groups-web.error-state.title')}
          actionLabel={t('groups-web.error-state.retry.label')}
        />
      );

    case Type.ADMIN_APPROVAL:
    default:
      return (
        <EmptyState
          wired
          data-hook="request-to-join"
          title={t('groups-web.group.private')}
          subtitle={t('groups-web.join.private')}
          action={
            <GroupMembershipButton
              groupId={groupId}
              bi={groupsRequestJoinAGroup({
                group_id: groupId,
                origin: 'group_feed_inside_page_btn',
                type: (privacyStatus || '').toLowerCase(),
              })}
            />
          }
        />
      );
  }

  function handleRetry() {
    router.reload();
  }
}

RestrictedState.displayName = 'RestrictedState';
