import React from 'react';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';

// UI
import { ProgressBar } from 'wix-ui-tpa/cssVars';
import { Box } from 'wui/Box';
import { Typography } from 'wui/Typography';

// types
import { type Step } from '@wix/ambassador-groups-onboarding-v1-onboarding/types';

type ProgressProps = {
  steps: Step[];
};

export const OnboardingProgress = ({ steps }: ProgressProps) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { label, progress } = getCompletedSteps(steps);
  return (
    <Box paddingLeft={20} paddingRight={20} paddingBottom={12}>
      <ProgressBar
        value={progress}
        prefixIndication={
          isMobile ? undefined : (
            <Typography variant="p2-12" noWrap>
              {label}
            </Typography>
          )
        }
      />
    </Box>
  );

  function getCompletedSteps(
    steps: Step[] = [],
    unit: string = t('groups-web.onboarding.steps.unit'),
  ) {
    const completedSteps = steps.filter((step) => step.status === 'COMPLETED');
    const label = `${completedSteps.length}/${steps.length} ${unit}`;
    const progress = (completedSteps.length / steps.length) * 100;
    return {
      label,
      progress,
    };
  }
};

OnboardingProgress.displayName = 'OnboardingProgress';
