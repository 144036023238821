import React from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from '@wix/tpa-router/react';

import { selectFeed, selectGroupSlugById } from 'store/selectors';

interface ISeoPaginationProps {
  groupId: string;
}

export function SeoPagination(props: ISeoPaginationProps) {
  const router = useRouter();

  const slug = useSelector(selectGroupSlugById(props.groupId));
  const { nextCursor, prevCursor } = useSelector(selectFeed);

  const prevHref = router.href(
    'group.discussion.feed',
    {
      slug,
      page: prevCursor,
    },
    { absolute: true },
  );

  const nextHref = router.href(
    'group.discussion.feed',
    {
      slug,
      page: nextCursor,
    },
    { absolute: true },
  );

  return (
    <ul style={{ display: 'none' }}>
      {prevCursor ? (
        <li>
          <a tabIndex={-1} href={prevHref}>
            prev
          </a>
        </li>
      ) : null}

      {nextCursor ? (
        <li>
          <a tabIndex={-1} href={nextHref}>
            next
          </a>
        </li>
      ) : null}
    </ul>
  );
}

SeoPagination.displayName = 'SeoPagination';
