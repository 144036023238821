import React from 'react';
import { useSelector } from 'react-redux';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { clubPostViewPostFullScreen } from '@wix/bi-logger-groups/v2';
import { ChevronLeft as ChevronLeftIcon } from '@wix/wix-ui-icons-common/on-stage';
import { useCurrentStateAndParams, useRouter } from '@wix/tpa-router/react';

import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';
import { GroupAppKey } from 'store/groups/types';
import type { IRootState } from 'store/types';
import {
  selectFeedItem,
  selectFeedPermissionsByGroup,
  selectFeedStatuses,
  selectGroupIdBySlug,
} from 'store/selectors';

import { Box } from 'wui/Box';
import { Button } from 'wui/Button';
import { EmptyState } from 'wui/EmptyState';
import { FeedItem, FeedItemSkeleton } from 'common/components/FeedItem';
import { Link } from 'common/components/Link';

import { RestrictedState } from '../../RestrictedState';

import * as DATA_HOOKS from '../dataHooks';

export const PostPage: React.FC = () => {
  const router = useRouter();
  const bi = useBi();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { params } = useCurrentStateAndParams();

  const { feed$ } = useController();
  const groupId = useSelector(selectGroupIdBySlug(params.slug));
  const permissions = useSelector(selectFeedPermissionsByGroup(groupId));
  const statuses = useSelector(selectFeedStatuses);
  const feedItem = useSelector((state: IRootState) =>
    selectFeedItem(state, params.feedItemId),
  );

  useDidUpdate(() => {
    if (permissions.canViewPosts) {
      feed$.get(groupId, params.feedItemId);
    }
  }, [permissions.canViewPosts]);

  React.useEffect(() => {
    bi.report(
      clubPostViewPostFullScreen({
        post_id: params.feedItemId,
        origin: 'discussion_tab',
      }),
    );
  }, []);

  return (
    <RestrictedState application={GroupAppKey.FEED_APP}>
      <Box padding={isMobile ? 'SP0 SP5' : 'SP0'}>
        <Link
          wired
          variant="secondary"
          state="group.discussion.feed"
          prefixIcon={<ChevronLeftIcon />}
        >
          {t('groups-web.discussion.post-not-found.back')}
        </Link>
      </Box>
      <div data-hook={DATA_HOOKS.FEED}>
        {(() => {
          if (statuses.fetch.pending) {
            return <FeedItemSkeleton />;
          }

          if (!feedItem) {
            return (
              <EmptyState
                wired
                title={t('groups-web.discussion.post-not-found.title')}
                subtitle={t('groups-web.discussion.post-not-found.subtitle')}
                action={
                  <Button onClick={openDiscussionPage}>
                    {t(
                      'groups-web.discussion.post-not-found.back-to-discussion',
                    )}
                  </Button>
                }
              />
            );
          }

          return <FeedItem commentsInitiallyExpaned item={feedItem} />;
        })()}
      </div>
    </RestrictedState>
  );

  function openDiscussionPage() {
    router.go('group.discussion.feed');
  }
};

PostPage.displayName = 'PostPage';
