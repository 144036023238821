import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSelector } from 'react-redux';
import {
  BadgeFill as BadgeFillIcon,
  Error as ErrorIcon,
  Replace as ReplaceIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import { useController } from 'common/context/controller';

// selectors
import {
  selectOnboarding,
  selectGroup,
  selectHasAdminRole,
} from 'store/selectors';

// UI
import { Box } from 'wui/Box';
import { TextButton } from 'wui/TextButton';
import { Spinner } from 'wui/Spinner';
import { EmptyState } from 'wui/EmptyState';
import { OnboardingStep } from '../OnboardingStep/OnboardingStep';
import { OnboardingProgress } from './OnboardingProgress';

import classes from './OnboardingSteps.scss';

type StateHandlerProps = {
  groupId: string;
};

export const OnboardingSteps: FC<StateHandlerProps> = (props) => {
  const { t } = useTranslation();
  const { onboarding$ } = useController();
  const {
    data: { steps },
    meta: { loading, error },
  } = useSelector(selectOnboarding);
  const group = useSelector(selectGroup(props.groupId));
  const isAdmin = useSelector(selectHasAdminRole(props.groupId));

  const isCompleted = steps.every((step) => step.status === 'COMPLETED');

  if (loading) {
    return (
      <Box direction="vertical" align="center" gap="SP4" padding="68px 0">
        <Spinner size={34} />
      </Box>
    );
  }

  if (error) {
    return (
      <EmptyState
        variant="section"
        title={t('groups-web.onboarding.error.title')}
        subtitle={t('groups-web.onboarding.error.subtitle')}
        action={
          <TextButton
            onClick={() => onboarding$.initialize(props.groupId)}
            prefixIcon={<ReplaceIcon />}
          >
            {t('groups-web.onboarding.error.refresh')}
          </TextButton>
        }
        image={<ErrorIcon size="32" />}
      />
    );
  }

  if (isCompleted) {
    return (
      <EmptyState
        variant="section"
        title={
          isAdmin
            ? t('groups-web.onboarding.success.admin.title')
            : t('groups-web.onboarding.success.member.title')
        }
        subtitle={
          isAdmin
            ? t('groups-web.onboarding.success.admin.subtitle', {
                groupName: group?.name,
              })
            : t('groups-web.onboarding.success.member.subtitle', {
                groupName: group?.name,
              })
        }
        image={<BadgeFillIcon className={classes.completed} size="32" />}
      />
    );
  }

  return (
    <>
      <OnboardingProgress steps={steps} />
      <Box direction="vertical" gap="0">
        {steps.map((step) => (
          <OnboardingStep
            groupId={props.groupId}
            key={step.stepKey}
            step={step}
          />
        ))}
      </Box>
    </>
  );
};

OnboardingSteps.displayName = 'OnboardingSteps';
