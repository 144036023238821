// The following constants is copy-pasted from ricos packages due to ricos does not export them properly.
// import { INSERT_PLUGIN_BUTTONS as RCE_INSERT_PLUGIN_BUTTONS } from 'wix-rich-content-editor-common'
// will import NOT ONLY needed constants, but ALL from common package. So bundle size increase dramatically.
// Attempt to avoid increasing bundle size is a reason why those constants 💩 are here.

export enum INSERT_PLUGIN_BUTTONS {
  IMAGE = 'ImagePlugin_InsertButton',
  VIDEO = 'VideoPlugin_InsertButton',
  GIF = 'GIFPlugin_InsertButton',
}

export enum BUTTON_TYPES {
  BUTTON = 'button',
  FILE = 'file',
}
