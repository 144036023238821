import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { groupsTabClicked } from '@wix/bi-logger-groups/v2';
import { getText } from '@wix/ricos';

import { useRicosFormat } from 'common/utils/ricos';
import { Link } from 'common/components/Link';

import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';
import { Card } from 'wui/Card';
import { Typography } from 'wui/Typography';
import { Skeleton } from 'wui/Skeleton';
import { CardHeader } from 'wui/CardHeader';
import { CardContent } from 'wui/CardContent';
import { CardActions } from 'wui/CardActions';

import {
  selectApplication,
  selectGroup,
  selectIsGroupLoading,
} from 'store/selectors';
import { GroupAppKey } from 'store/groups/types';

import classes from './AboutGroup.scss';

interface IAboutGroupProps {
  groupId: string;
  maxLength?: number;
}

export function AboutGroup(props: IAboutGroupProps) {
  const { groupId } = props;

  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const bi = useBi();

  const group = useSelector(selectGroup(groupId));
  const isLoading = useSelector(selectIsGroupLoading(groupId));
  const about = useSelector(
    selectApplication({
      groupId,
      application: GroupAppKey.ABOUT_APP,
    }),
  );

  const descriptionRicos = useRicosFormat(group.description);

  const description = useMemo(
    () => (descriptionRicos ? getText(descriptionRicos).join(' ') : ''),
    [descriptionRicos],
  );

  const isTruncated = description.length > props.maxLength!;

  return (
    <Card
      sideBorders={!isMobile}
      data-hook="about-widget"
      className={classes.root}
    >
      <CardHeader
        wired
        title={about?.customName || t('groups-web.discussion.about.title')}
      />
      <CardContent>
        <Show if={isLoading}>
          <Skeleton />
        </Show>
        <Hide if={!!isLoading}>
          <Typography className={classes.description}>
            {description.substring(0, props.maxLength!) ||
              t('groups-web.discussion.about.description')}

            <Show if={isTruncated}>...</Show>
          </Typography>
        </Hide>
      </CardContent>
      <Show if={isTruncated}>
        <CardActions>
          <Link
            wired
            state="group.about"
            params={{ slug: group.slug }}
            onClick={handleReadMoreClick}
          >
            {t('groups-web.more')}
          </Link>
        </CardActions>
      </Show>
    </Card>
  );

  function handleReadMoreClick() {
    bi.report(
      groupsTabClicked({
        group_id: group.id as string,
        origin: 'pinned_window',
        name: 'about',
      }),
    );
  }
}

AboutGroup.displayName = 'AboutGroup';
AboutGroup.defaultProps = {
  maxLength: 60,
};
