import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, useBi } from '@wix/yoshi-flow-editor';
import { useController } from 'common/context/controller';

// UI
import { Card } from 'wui/Card';
import { CardHeader } from 'wui/CardHeader';
import { CardContent } from 'wui/CardContent';
import { IconButton } from 'wui/IconButton';
import { Close as CloseIcon } from '@wix/wix-ui-icons-common/on-stage';

// components
import { OnboardingSteps } from './OnboardingSteps';

// selectors
import {
  selectHasAdminRole,
  selectOnboarding,
  selectGroup,
} from 'store/selectors';

// bi
import { onboardingViewSrc111Evid532 } from '@wix/bi-logger-groups-data/v2';

// types
import { OnboardingStatus } from '@wix/ambassador-groups-onboarding-v1-onboarding/types';

type OnboardingProps = {
  groupId: string;
};

export const Onboarding: React.FC<OnboardingProps> = ({ groupId }) => {
  const { t } = useTranslation();
  const { onboarding$ } = useController();
  const bi = useBi();

  const isAdmin = useSelector(selectHasAdminRole(groupId));
  const group = useSelector(selectGroup(groupId));

  const {
    data: { status, steps },
    meta: { loading },
  } = useSelector(selectOnboarding);

  const hideOnboarding = [
    OnboardingStatus.INITIALIZING,
    OnboardingStatus.COMPLETED,
  ].includes(status);

  const isAllCompleted = steps.every((step) => step.status === 'COMPLETED');
  const completedStepsLength = steps.filter(
    (step) => step.status === 'COMPLETED',
  ).length;
  const isPlaceholderShown = isAllCompleted || loading;

  useEffect(() => {
    if (status === OnboardingStatus.READY) {
      bi.report(
        onboardingViewSrc111Evid532({
          user_role: group.role,
          group_id: groupId,
          total_steps: steps.length,
          completed_steps_cnt: completedStepsLength,
          steps_list: JSON.stringify(
            steps.map((step) => ({
              ...step,
              completed: step.status === 'COMPLETED',
            })),
          ),
        }),
      );
    }
  }, [status]);

  if (hideOnboarding) {
    return null;
  }

  return (
    <Card
      sideBorders
      data-hook="onboarding-widget"
      {...(isPlaceholderShown && { gap: '0' })}
    >
      <CardHeader
        wired
        title={
          isPlaceholderShown
            ? undefined
            : isAdmin
            ? t('groups-web.onboarding.title.admin')
            : t('groups-web.onboarding.title.member')
        }
        action={
          <IconButton
            onClick={() => onboarding$.closeOnboarding(groupId)}
            icon={<CloseIcon />}
          />
        }
      />
      <CardContent padding={0}>
        <OnboardingSteps groupId={groupId} />
      </CardContent>
    </Card>
  );
};

Onboarding.displayName = 'Onboarding';
