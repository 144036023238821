import React from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import {
  selectIsAppAvailable,
  selectUpcomingAndPastEvents,
} from 'store/selectors';
import { GroupAppKey } from 'store/groups/types';

import { useDatePartsFormatter, useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';
import { Link } from 'common/components/Link';

import { Card } from 'wui/Card';
import { CardHeader } from 'wui/CardHeader';
import { CardContent } from 'wui/CardContent';
import { CardActions } from 'wui/CardActions';
import { List } from 'wui/List';
import { ListItem } from 'wui/ListItem';
import { ListItemText } from 'wui/ListItemText';

import { getScheduleTbdMessage } from './helpers';

interface IGroupEventsProps {
  groupId: string;
}

export function UpcomingGroupEvents(props: IGroupEventsProps) {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const datePartsFormatter = useDatePartsFormatter({
    day: 'numeric',
    month: 'short',
    weekday: 'short',
  });

  const { events$ } = useController();
  const [upcoming] = useSelector(selectUpcomingAndPastEvents);

  const isAvailable = useSelector(
    selectIsAppAvailable({
      groupId: props.groupId,
      application: GroupAppKey.EVENTS_APP,
    }),
  );

  useDidUpdate(() => {
    if (isAvailable) {
      events$.fetch(props.groupId);
    }
  }, [isAvailable]);

  if (!isAvailable || !upcoming.length) {
    return null;
  }

  return (
    <Card sideBorders={!isMobile}>
      <CardHeader
        wired
        title={t('groups-web.discussion.events.widget.title')}
      />
      <CardContent>
        <List disablePadding>
          {upcoming.slice(0, 2).map((event) => {
            const tbd = getScheduleTbdMessage(event);
            const parts = datePartsFormatter(
              event.dateAndTimeSettings?.startDate,
            );

            const date = tbd || `${parts.day} ${parts.month} ${parts.weekday}`;

            return (
              <ListItem disablePadding key={event.id}>
                <ListItemText title={`${date} | '${event.title}'`} />
              </ListItem>
            );
          })}
        </List>
      </CardContent>
      <CardActions>
        <Link wired state="group.events">
          {t('groups-web.discussion.events.widget.view-all')}
        </Link>
      </CardActions>
    </Card>
  );
}

UpcomingGroupEvents.displayName = 'UpcomingGroupEvents';
