import React from 'react';
import { useSelector } from 'react-redux';
import { useBi, useEnvironment } from '@wix/yoshi-flow-editor';
import { useCurrentStateAndParams } from '@wix/tpa-router/react';
import { groupCreatePostClick, groupFeedView } from '@wix/bi-logger-groups/v2';

import type { RicosEditorType } from '@wix/ricos';
import { EditorEventsProvider } from '@wix/ricos';

import { EFilterKeys } from 'api/feed/constants';
import {
  selectDialog,
  selectFeedPermissionsByGroup,
  selectGroupIdBySlug,
  selectTopic,
  selectFeed,
  selectFeedItems,
} from 'store/selectors';
import { GroupAppKey } from 'store/groups/types';

import { useController } from 'common/context/controller';
import { BUTTON_TYPES } from 'common/ContentEditor/consts';
import { useDidUpdate } from 'common/hooks';

import { FeedItemEditorDialog } from 'common/components/FeedItemEditorDialog';
import { EPluginButton } from 'common/components/RichContent/types';
import { FeedItemList } from 'common/components/FeedItemList';

import { RestrictedState } from 'Group/Widget/RestrictedState';
import { Show } from 'wui/Show';

import { CreatePost } from './CreatePost';
import { AppliedFilters } from './AppliedFilters';
import { GroupTopics } from '../GroupTopics';
import { SeoPagination } from './SeoPagination';
import { Onboarding } from '../Onboarding';

export interface IGroupFeedProps {}

interface IIntent {
  button: EPluginButton;
  event: React.MouseEvent | File[];
}

export const GroupFeed: React.FC<IGroupFeedProps> = () => {
  const bi = useBi();
  const { application$, feed$ } = useController();
  const { params } = useCurrentStateAndParams();
  const { isMobile } = useEnvironment();

  const groupId = useSelector(selectGroupIdBySlug(params.slug));
  const permissions = useSelector(selectFeedPermissionsByGroup(groupId));
  const dialog = useSelector(selectDialog('createPost'));
  const topic = useSelector(selectTopic(params[EFilterKeys.TOPICS]));

  const state = useSelector(selectFeed);
  const items = useSelector(selectFeedItems);

  const $editor = React.useRef<RicosEditorType>();

  const [intent, setIntent] = React.useState<IIntent>();

  useDidUpdate(() => {
    if (permissions.canViewPosts) {
      feed$.fetch(groupId);
    }
  }, [permissions.canViewPosts]);

  React.useEffect(() => {
    if (dialog.isOpen && intent) {
      Promise.resolve().then(() => openPlugin(intent));
    }
  }, [dialog.isOpen, intent?.button]);

  React.useEffect(() => {
    bi.report(
      groupFeedView({
        group_id: groupId,
        origin: 'discussion_tab',
      }),
    );
  }, []);

  return (
    <RestrictedState application={GroupAppKey.FEED_APP}>
      <AppliedFilters />
      <Show if={permissions.canCreatePosts}>
        <CreatePost onClick={handleCreatePostClick} />
      </Show>

      {isMobile ? (
        <>
          <Onboarding groupId={groupId} />
          <GroupTopics groupId={groupId} />
        </>
      ) : null}

      <FeedItemList
        state={state}
        items={items}
        onCreatePost={handleCreatePostClick}
        onFetch={handleFetchMore}
      />
      <SeoPagination groupId={groupId} />
      <EditorEventsProvider>
        <FeedItemEditorDialog
          forTopic={topic}
          groupId={groupId}
          isOpen={dialog.isOpen}
          biExtra={dialog.params}
          onClose={closeCreateModal}
          onRefChange={handleEditorRefChange}
        />
      </EditorEventsProvider>
    </RestrictedState>
  );

  function handleFetchMore(cursor?: string) {
    feed$.fetch(groupId, { cursor }, true);
  }

  function handlePluginOpen(
    button: EPluginButton,
    event: React.MouseEvent | File[],
  ) {
    setIntent({ event, button });
  }

  function handleEditorRefChange(node: RicosEditorType) {
    $editor.current = node;
  }

  function openPlugin(intent?: IIntent) {
    if (!$editor.current || !intent) {
      return;
    }

    const { buttons } = $editor.current.getToolbarProps('INSERT_PLUGIN' as any);
    const button = buttons[intent.button];

    switch (button?.type) {
      case BUTTON_TYPES.BUTTON:
        button.onClick(intent.event);
        break;

      case BUTTON_TYPES.FILE:
        button.onChange(intent.event);
        break;

      default:
        break;
    }

    setIntent(undefined);
  }

  function handleCreatePostClick() {
    bi.report(
      groupCreatePostClick({
        groupId,
        origin: 'top_rce_area',
      }),
    );

    feed$.showCreatePostDialog(groupId, {
      origin: 'livesite_group_page',
      page_name: 'livesite_group_page',
    });
  }

  function closeCreateModal() {
    application$.closeDialog('createPost');
  }
};

GroupFeed.displayName = 'GroupFeed';
