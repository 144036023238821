import React from 'react';

// UI
import { ListItem } from 'wui/ListItem';
import { ListItemButton } from 'wui/ListItemButton';
import { ListItemIcon } from 'wui/ListItemIcon';
import { ListItemText } from 'wui/ListItemText';
import { Typography } from 'wui/Typography';
import { SelectFill as SelectFillIcon } from '@wix/wix-ui-icons-common/on-stage';

// types
import { Status } from '@wix/ambassador-groups-onboarding-v1-onboarding/types';

import classes from './Step.scss';

type OnboardingStepProps = {
  disabled?: boolean;
  icon: JSX.Element;
  title: string;
  status?: Status;
  onClick: () => void;
};

export const Step: React.FC<OnboardingStepProps> = (props) => {
  const isCompleted = props.status === 'COMPLETED';
  const completedClassName = isCompleted ? classes.completed : undefined;

  return (
    <ListItem className={classes.root}>
      <ListItemButton
        onClick={props.onClick}
        disabled={props.disabled}
        className={classes.cta}
      >
        <ListItemIcon className={completedClassName}>
          {isCompleted ? <SelectFillIcon /> : props.icon}
        </ListItemIcon>
        <ListItemText
          title={
            <Typography className={completedClassName}>
              {props.title}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

Step.displayName = 'Step';
